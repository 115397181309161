import React from "react";
import { Add, Loading, Table, Title } from "../../components";
import { useFETCH, useFilter } from "../../APIs/useMyAPI";

const Sections = () => {
  const { filter } = useFilter();
  const { data, deleteItem, isLoading } = useFETCH(
    `admin/sections${filter.get("page") ? "?page=" + filter.get("page") : ""}`,
    "admin/sections"
  );
  const { printClick } = useFETCH(`admin/sections`);
  return (
    <div>
      {isLoading ? <Loading /> : ""}
      <Title title="الأقسام" />
      <Table
        print={printClick}
        pageCount={Math.ceil(data?.data.data.total / data?.data.data.per_page)}
        idDel={deleteItem}
        thead={["اسم القسم", "اسم المدير"]}
        tbody={["name", "admin"]}
        tdata={data?.data.data.data}
        links={[
          { name: "عرض التقارير", link: "/sections/report/" },
          { name: "عرض مراكز التكلفة", link: "/sections/cost-centers/" },
          { name: "عرض العمال ", link: "/sections/worker/" },
          { name: "تعديل", link: "/sections/" },
        ]}
      />
      <Add link="/sections/add" />
    </div>
  );
};

export default Sections;

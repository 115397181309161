import React, { useEffect } from "react";
import { Col, Container, Row } from "../../Grid-system";
import { Back, Loading } from "../../components";
import { useFETCH, usePOST } from "../../APIs/useMyAPI";
import { useParams } from "react-router-dom";

const AddCostCenters = () => {
  const { id } = useParams();
  const {
    error,
    formData,
    handleChangeInput,
    handleSubmit,
    setFormData,
    loading,
  } = usePOST();
  const { data } = useFETCH(`admin/cost-centers/${id}`);
  let dataEdite = data?.data.data;
  useEffect(() => {
    id !== "add" &&
      setFormData({
        project_name: dataEdite?.project_name,
        contract_number: dataEdite?.contract_number,
        contract_value: dataEdite?.contract_value,
      });
  }, [dataEdite]);
  const handleSubmitMain = (e) => {
    e.preventDefault();
    handleSubmit(
      id !== "add" ? `admin/cost-centers/${id}` : `admin/cost-centers`
    );
  };

  return (
    <div>
      <Container>
        {loading ? <Loading /> : ""}
        <div className="border border-Green rounded-2xl">
          <Row justify="center" className="p-5">
            <Col md={7}>
              <input
                type="text"
                name="project_name"
                value={formData?.project_name}
                onChange={handleChangeInput}
                placeholder="اسم المشروع"
                className=" border border-black py-3 rounded-xl"
              />
            </Col>
            <Col md={7}>
              <input
                type="text"
                name="contract_number"
                value={formData?.contract_number}
                onChange={handleChangeInput}
                placeholder="رقم العقد"
                className=" border border-black py-3 rounded-xl"
              />
            </Col>
            <Col md={7}>
              <input
                type="text"
                name="contract_value"
                value={formData?.contract_value}
                onChange={handleChangeInput}
                placeholder="قيمة العقد"
                className=" border border-black py-3 rounded-xl"
              />
            </Col>
            <Col md={7}>
              <div className="text-red-600">{error}</div>
              <button
                onClick={handleSubmitMain}
                className="w-full bg-Green py-3 rounded-2xl text-xl text-white hover:opacity-80"
              >
                حفظ
              </button>
            </Col>
          </Row>
        </div>
      </Container>
      <Back />
    </div>
  );
};

export default AddCostCenters;

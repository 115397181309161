import React from "react";
import { Add, Loading, Table, Title } from "../../components";
import { useFETCH, useFilter } from "../../APIs/useMyAPI";
import { useParams } from "react-router-dom";

const Report = () => {
  const { id, date, cost_center_id } = useParams();
  const { filter } = useFilter();
  const { data, isLoading } = useFETCH(
    `admin/sections/${id}/report/details?date=${date}&cost_center_id=${cost_center_id}&page=${
      filter.get("page") ? filter.get("page") : 1
    }`
  );

  return (
    <div>
      {isLoading ? <Loading /> : ""}
      <Title title="التقارير" />
      <Table
        pageCount={Math.ceil(data?.data.data.total / data?.data.data.per_page)}
        thead={[
          "اسم العامل",
          "ساعات أساسية ",
          "ساعات إضافية",
          "الكلفة الإجمالية",
          "الكلفة بدون إضافي",
        ]}
        tbody={[
          "name",
          "main_hours",
          "extra_hours",
          "main_hours_cost",
          "extra_hours_cost",
        ]}
        tdata={data?.data.data.data}
      />
      <Add link="/sections/add" />
    </div>
  );
};

export default Report;

import { useContextHook } from "../../Context/ContextOPen";
import "./SideBar.css";
import { NavLink } from "react-router-dom";
import logo from "../../images/siab.png";

function SideBar() {
  const { openMenu } = useContextHook();
  return (
    <div
      className={`${openMenu ? "w-[360px]" : " w-[0px]"} ${
        openMenu
          ? " max-sm:translate-x-0 max-sm:w-[100%] "
          : " max-sm:-translate-x-full"
      } sidebar h-[100vh] bg-Green max-sm:h-[100%] sticky max-sm:fixed left-0 top-0 transition-all overflow-y-scroll  py-10 z-30`}
    >
      <div className="px-3">
        <ul className="text-white text-center font-semibold space-y-4 max-sm:mt-10">
          <li className="">
            <img
              src={logo}
              alt=""
              className="mx-auto w-[200px] rounded-xl max-sm:pt-5"
            />
          </li>
          <li className=" ">
            <NavLink
              to={"/sections"}
              className="text-xl py-2 block rounded-3xl hover:text-Brown hover:bg-white hover:text-[#2F2C8F]"
            >
              الأقسام
            </NavLink>
          </li>

          <li className=" ">
            <NavLink
              to={"/department-managers"}
              className="text-xl py-2 block rounded-3xl hover:text-Brown hover:bg-white hover:text-[#2F2C8F]"
            >
              مدراء الأقسام
            </NavLink>
          </li>
          <li className=" ">
            <NavLink
              to={"/cost-centers"}
              className="text-xl py-2 block rounded-3xl hover:text-Brown hover:bg-white hover:text-[#2F2C8F]"
            >
              مراكز التكلفة
            </NavLink>
          </li>
          <li className=" ">
            <NavLink
              to={"/workers"}
              className="text-xl py-2 block rounded-3xl hover:text-Brown hover:bg-white hover:text-[#2F2C8F]"
            >
              العمال
            </NavLink>
          </li>
          <li className=" ">
            <NavLink
              to={"/administrative-cost"}
              className="text-xl py-2 block rounded-3xl hover:text-Brown hover:bg-white hover:text-[#2F2C8F]"
            >
              التكلفة الإدارية
            </NavLink>
          </li>

          <li className="pb-10">
            <NavLink
              to={"/Change-Password"}
              className="text-xl py-2 block rounded-3xl hover:text-Brown hover:bg-white hover:text-[#2F2C8F]"
            >
              تغيير كلمة السر
            </NavLink>
          </li>
        </ul>
      </div>
    </div>
  );
}

export default SideBar;

import React, { useState } from "react";
import { Col, Row } from "../../Grid-system";
import { Loading, Title } from "../../components";
import { usePOST } from "../../APIs/useMyAPI";

const ChangePassword = () => {
  const [biShow, setBiShow] = useState(false);
  const { handleSubmit, error, loading, handleChangeInput } = usePOST({});
  const handleSubmitMain = () => {
    handleSubmit("admin/change-password");
  };
  return (
    <div>
      <Title title="Change Password" />
      <Row justify="center">
        <Col md={7}>
          <div className="border-2 border-Green rounded-2xl  py-10 md:px-16 bgimg">
            <input
              type={biShow ? "text" : "password"}
              name="old_password"
              onChange={handleChangeInput}
              className="border border-Green py-4 rounded-2xl my-3"
              placeholder="كلمة السر القديم"
            />
            <input
              onChange={handleChangeInput}
              name="password"
              type={biShow ? "text" : "password"}
              className="border border-Green py-4 rounded-2xl my-3"
              placeholder="كلمة السر الجديدة"
            />
            <input
              onChange={handleChangeInput}
              name="password_confirmation"
              type={biShow ? "text" : "password"}
              className="border border-Green py-4 rounded-2xl my-3"
              placeholder="تأكيد كلمة السر"
            />
            <div className="text-end text-l text-Brown flex items-center justify-start gap-1 space-x-2">
              <input
                type="checkbox"
                className="relative w-5 h-5"
                onChange={(e) => setBiShow(e.target.checked)}
              />
              <span>عرض كلمة السر</span>
            </div>
            {loading ? <Loading /> : ""}
            <div className="text-red-600">{error}</div>
            <div
              onClick={handleSubmitMain}
              className="cursor-pointer w-full border border-Green py-3 mt-10 rounded-2xl text-white font-semibold text-xl  text-center bg-gradient-to-l to-Green from-Green"
            >
              حفظ كلمة السر
            </div>
          </div>
        </Col>
      </Row>
    </div>
  );
};

export default ChangePassword;

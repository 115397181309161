import React from "react";

const Back = () => {
  return (
    <div className="fixed bottom-10 left-10 flex flex-col gap-2 items-end">
      <div
        className="bg-Green p-5 rounded-xl hover:bg-opacity-70  text-center font-semibold text-white cursor-pointer"
        onClick={() => window.history.go(-1)}
      >
        رجوع
      </div>
    </div>
  );
};

export default Back;

import { useFilter } from "../../APIs/useMyAPI";
import { Col, Row } from "../../Grid-system";

function Filter() {
  const { filter, setFilter } = useFilter();

  return (
    <Row className="" justify={"end"}>
      {filter.get("date") === "2000-01-01" && (
        <Col md={5} className={`flex justify-end items-center gap-5 flex-wrap`}>
          <div className=" border border-Secondary p-2 rounded-xl relative flex items-center justify-center">
            <span className="absolute -top-3 bg-white px-2 left-3">
              Start Date
            </span>
            <input
              type="date"
              value={filter.get("start_date")}
              onChange={(e) => {
                setFilter({
                  date: filter.get("date") ? filter.get("date") : "",
                  start_date: e.target.value,
                  end_date: filter.get("end_date")
                    ? filter.get("end_date")
                    : "",
                  page: filter.get("page") ? filter.get("page") : "1",
                });
              }}
            />
          </div>
          <div className=" border border-Secondary p-2 rounded-xl relative flex items-center justify-center">
            <span className="absolute -top-3 bg-white px-2 left-3">
              End Date
            </span>
            <input
              type="date"
              value={filter.get("end_date")}
              onChange={(e) => {
                setFilter({
                  date: filter.get("date") ? filter.get("date") : "",
                  end_date: e.target.value,
                  start_date: filter.get("start_date")
                    ? filter.get("start_date")
                    : "",
                  page: filter.get("page") ? filter.get("page") : "1",
                });
              }}
            />
          </div>
        </Col>
      )}
      <Col md={2} className={"flex justify-end"}>
        <div className="border border-Main flex space-x-2 p-2 rounded-xl max-sm:w-full">
          <select
            value={filter.get("date")}
            onChange={(e) =>
              setFilter({
                date: e.target.value,
                page: filter.get("page") ? filter.get("page") : "1",
              })
            }
            className="outline-none text-center"
          >
            <option value="" onClick={() => setFilter({})}>
              الكل
            </option>
            <option
              className="text-black"
              value={`${new Date().getFullYear()}-${
                new Date().getMonth() === 9 ||
                new Date().getMonth() === 10 ||
                new Date().getMonth() === 11 ||
                new Date().getMonth() === 12
                  ? new Date().getMonth() + 1
                  : "0" + (new Date().getMonth() + 1)
              }-${new Date().getDate() - 1}`}
            >
              يوم
            </option>
            <option
              className="text-black"
              value={`${new Date().getFullYear()}-${
                new Date().getMonth() === 9 ||
                new Date().getMonth() === 10 ||
                new Date().getMonth() === 11 ||
                new Date().getMonth() === 12
                  ? new Date().getMonth() + 1
                  : "0" + (new Date().getMonth() + 1)
              }-${new Date().getDate() - 7}`}
            >
              اسبوع
            </option>
            <option
              className="text-black"
              value={`${new Date().getFullYear()}-${
                new Date().getMonth() === "10" ||
                new Date().getMonth() === "11" ||
                new Date().getMonth() === "12"
                  ? new Date().getMonth()
                  : "0" + new Date().getMonth()
              }-${new Date().getDate()}`}
            >
              شهر
            </option>
            <option className="text-black" value="2000-01-01">
              يوم من الى
            </option>
          </select>
        </div>
      </Col>
    </Row>
  );
}
export default Filter;

import { BiMenu } from "react-icons/bi";
import Logo from "../../images/siab.png";
import { useContextHook } from "../../Context/ContextOPen";

import { useState } from "react";
import { Link } from "react-router-dom";
import { usePOST } from "../../APIs/useMyAPI";

const Navbar = () => {
  const { changeMenu } = useContextHook();
  const [sure, setSure] = useState(false);
  const { handleSubmit } = usePOST({});
  return (
    <>
      {sure && (
        <>
          <div
            className="fixed  top-0 left-0 w-full h-full bg-black bg-opacity-60 z-[50]"
            onClick={() => setSure(false)}
          ></div>
          <div
            className={`fixed  top-1/2 left-1/2 -translate-x-1/2 -translate-y-1/2 popup z-50 flex justify-center items-center`}
          >
            <div className="bg-white z-[60] rounded-3xl w-[500px] max-w-[500px] min-h-[200px]">
              <p className="font-semibold text-3xl text-center py-7">
                هل أنت متأكد من التسجيل خروج؟
              </p>
              <div className="flex items-end m-5">
                <div className="flex gap-9 mx-3">
                  <Link to="/login">
                    <div className="flex gap-9 mx-3">
                      <div
                        className="px-7 py-3 bg-green-600 my-2 rounded-xl text-white cursor-pointer hover:bg-opacity-75"
                        onClick={() => {
                          handleSubmit("admin/logout");
                          localStorage.clear();
                        }}
                      >
                        نعم
                      </div>
                    </div>
                  </Link>
                </div>

                <button
                  onClick={() => setSure(false)}
                  className="px-7 py-3 bg-red-600 my-2 rounded-xl text-white cursor-pointer hover:bg-opacity-75"
                >
                  لا
                </button>
              </div>
            </div>
          </div>
        </>
      )}

      <div className="container mx-auto sticky top-0 z-30 bg-white flex items-center justify-between flex-wrap mb-5 pb-2 border-b-2 border-Brown">
        <div>
          <BiMenu
            size={35}
            className="text-Brown cursor-pointer"
            onClick={changeMenu}
          />
        </div>
        <div className="">
          <img src={Logo} alt="" className="w-32 " />
        </div>
        <div className="flex gap-9 mx-3">
          <div
            className="px-7 py-3 bg-Green my-2 rounded-xl text-white cursor-pointer hover:bg-opacity-75"
            onClick={() => setSure(true)}
          >
            تسجيل خروج
          </div>
        </div>
      </div>
    </>
  );
};

export default Navbar;

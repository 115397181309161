import React from "react";
import { Add, Loading, Table, Title } from "../../components";
import { useFETCH, useFilter } from "../../APIs/useMyAPI";

const DepartmentManagers = () => {
  const { filter } = useFilter();
  const { data, deleteItem, isLoading } = useFETCH(
    `admin/sections/admins${
      filter.get("page") ? "?page=" + filter.get("page") : ""
    }`,
    "admin/sections/admins"
  );

  return (
    <div>
      {isLoading ? <Loading /> : ""}
      <Title title="مدراء الأقسام" />
      <Table
        pageCount={Math.ceil(data?.data.data.total / data?.data.data.per_page)}
        thead={["اسم المستخدم ", "اسم ", "اسم القسم"]}
        tbody={["username", "name", "section"]}
        tdata={data?.data.data.data}
        idDel={deleteItem}
        links={[{ name: "تعديل", link: "/department-managers/" }]}
      />
      <Add link="/department-managers/add" />
    </div>
  );
};

export default DepartmentManagers;

import React from "react";
import { Add, Loading, Table, Title } from "../../components";
import { useParams } from "react-router-dom";
import { useFETCH, useFilter } from "../../APIs/useMyAPI";

const CostCenters = () => {
  const { filter } = useFilter();
  const { id } = useParams();
  const { data, deleteItem, isLoading } = useFETCH(
    `admin/sections/${id}/cost-centers${
      filter.get("page") ? "?page=" + filter.get("page") : ""
    }`,
    `admin/sections/${id}/cost-centers`
  );
  const { data: section } = useFETCH(`admin/sections/${id}`);

  return (
    <div>
      <Title title={` ${section?.data.data.name} > مراكز التكلفة`} />
      {isLoading ? <Loading /> : ""}
      <Table
        pageCount={Math.ceil(data?.data.data.total / data?.data.data.per_page)}
        idDel={deleteItem}
        thead={["اسم المشروع ", "رقم العقد ", "قيمة العقد"]}
        tbody={["project_name", "contract_number","contract_value"]}
        tdata={data?.data.data.data}
        links={[
          { name: "عرض العمال ", link: "/cost-centers/workers/" },
          { name: "تقارير ", link: `/sections/cost-centers/${id}/report/` },
        ]}
      />
      <Add link={`/sections/cost-centers/${id}/add`} />
    </div>
  );
};

export default CostCenters;

import React, { useEffect } from "react";
import { Col, Container, Row } from "../../Grid-system";
import { Loading } from "../../components";
import { useFETCH, usePOST } from "../../APIs/useMyAPI";

const AdministrativeCost = () => {
  const {
    error,
    formData,
    handleChangeInput,
    handleSubmit,
    setFormData,
    loading,
  } = usePOST();
  const { data } = useFETCH(`admin/site-info`);
  let dataEdite = data?.data.data;
  useEffect(() => {
    setFormData({
      administrative_hours_percentage:
        dataEdite?.administrative_hours_percentage,
    });
  }, [dataEdite]);
  const handleSubmitMain = (e) => {
    e.preventDefault();
    handleSubmit(`admin/site-info`,true );
  };

  return (
    <div>
      <Container>
        {loading ? <Loading /> : ""}
        <div className="border border-Green rounded-2xl">
          <Row justify="center" className="p-5">
            <Col md={7}>
              <h1 className="text-xl py-5">كلفة الساعة الإدارية :</h1>
              <input
                type="text"
                name="administrative_hours_percentage"
                value={formData?.administrative_hours_percentage}
                onChange={handleChangeInput}
                placeholder="التكلفة"
                style={{ direction: "ltr" }}
                className=" border border-black py-3 rounded-xl text-center "
              />
            </Col>

            <Col md={7} className="py-5">
              <div className="text-red-600">{error}</div>
              <button
                onClick={handleSubmitMain}
                className="w-full bg-Green py-3 rounded-2xl text-xl text-white hover:opacity-80"
              >
                حفظ
              </button>
            </Col>
          </Row>
        </div>
      </Container>
    </div>
  );
};

export default AdministrativeCost;

import React from "react";
import { Add, Loading, Table, Title } from "../../components";
import { useFETCH, useFilter } from "../../APIs/useMyAPI";
import { useParams } from "react-router-dom";

const CenterSectionRepo = () => {
  const { id, date, cost_center_id, section_id } = useParams();
  const { filter } = useFilter();
  const { data, isLoading } = useFETCH(
    `admin/cost-centers/${id}/report/details?date=${date}&cost_center_id=${cost_center_id}&section_id=${section_id}${
      filter.get("page") ? "&page=" + filter.get("page") : ""
    }`
  );
  return (
    <div>
      {isLoading ? <Loading /> : ""}
      <Title title="التقارير" />
      <Table
        pageCount={Math.ceil(data?.data.data.total / data?.data.data.per_page)}
        thead={[
          "اسم العامل",
          "ساعات أساسية ",
          "ساعات إضافية",
          "كلفة الساعات اساسية",
          "كلفة الساعات إضافي",
        ]}
        tbody={[
          "name",
          "main_hours",
          "extra_hours",
          "main_hours_cost",
          "extra_hours_cost",
        ]}
        tdata={data?.data.data.data}
      />
      <Add link="/sections/add" />
    </div>
  );
};

export default CenterSectionRepo;

import { Col, Container, Row } from "../../Grid-system";
import { Back, Loading } from "../../components";
import { useFETCH, usePOST } from "../../APIs/useMyAPI";
import { useParams } from "react-router-dom";

const AddCostCenters = () => {
  const { id } = useParams();
  const { error, formData, handleChangeInput, handleSubmit, loading } =
    usePOST();
  const { data: sectionData } = useFETCH("admin/cost-centers?paginate=0");
  const handleSubmitMain = (e) => {
    e.preventDefault();
    handleSubmit(`admin/sections/${id}/cost-centers`);
  };

  return (
    <div>
      <Container>
        {loading ? <Loading /> : ""}
        <div className="border border-Green rounded-2xl">
          <Row justify="center" className="p-5">
            <Col md={7}>
              <select
                name="cost_center_id"
                value={formData?.cost_center_id}
                onChange={handleChangeInput}
                className="w-full  text-center outline-none border border-black py-3 rounded-xl"
              >
                <option value=""></option>
                {sectionData?.data.data.map((e, i) => (
                  <option key={i} value={e.id}>
                    {e.project_name}
                  </option>
                ))}
              </select>
            </Col>
            <Col md={7}>
              <div className="text-red-600">{error}</div>
              <button
                onClick={handleSubmitMain}
                className="w-full bg-Green py-3 rounded-2xl text-xl text-white hover:opacity-80"
              >
                حفظ
              </button>
            </Col>
          </Row>
        </div>
      </Container>
      <Back />
    </div>
  );
};

export default AddCostCenters;

import React, { useEffect } from "react";
import { Col, Container, Row } from "../../Grid-system";
import { Back, Loading } from "../../components";
import { useFETCH, usePOST } from "../../APIs/useMyAPI";
import { useParams } from "react-router-dom";

const AddSections = () => {
  const { id } = useParams();
  const {
    error,
    formData,
    handleChangeInput,
    handleSubmit,
    setFormData,
    loading,
  } = usePOST();
  const { data } = useFETCH(`admin/sections/${id}`);
  let dataEdite = data?.data.data;
  useEffect(() => {
    id !== "add" &&
      setFormData({
        name: dataEdite?.name,
      });
  }, [dataEdite]);
  const handleSubmitMain = (e) => {
    e.preventDefault();
    handleSubmit(id !== "add" ? `admin/sections/${id}` : "admin/sections");
  };
  return (
    <div>
      <Container>
        {loading ? <Loading /> : ""}
        <div className="border border-Green rounded-2xl">
          <Row justify="center" className="p-5">
            <Col md={7}>
              <input
                type="text"
                name="name"
                value={formData?.name}
                onChange={handleChangeInput}
                placeholder="اسم القسم"
                className=" border border-black py-3 rounded-xl"
              />
            </Col>
            <Col md={7}>
              <div className="text-red-600">{error}</div>
              <button
                onClick={handleSubmitMain}
                className="w-full bg-Green py-3 rounded-2xl text-xl text-white hover:opacity-80"
              >
                حفظ
              </button>
            </Col>
          </Row>
        </div>
      </Container>
      <Back />
    </div>
  );
};

export default AddSections;

import React, { useEffect, useRef, useState } from "react";
import { CiMenuKebab } from "react-icons/ci";
import Delete from "../Delete/Delete";
import { Link } from "react-router-dom";
import Pagination from "../Pagination/Pagination";

const Table = ({
  thead,
  tbody,
  tdata,
  links,
  idDel,
  children,
  pageCount,
  print,
  report,
  reportCenter,
  reportCenterSection,
  archive,
  archiveFun,
}) => {
  return (
    <div>
      <div className=" mx-auto w-full  py-4 overflow-x-scroll h-full ">
        <Pagination pageCount={pageCount}>
          <table className=" textl text-center w-full mx-auto border  overflow-hidden  rounded-2xl  h-full">
            <thead className="bg-Green text-white w-full  pb-52 ">
              <tr>
                <td className="p-5 w-5"></td>
                {thead?.map((e, i) => (
                  <td key={i} className="p-5">
                    {e}
                  </td>
                ))}
              </tr>
            </thead>
            <tbody>
              {tdata &&
                tdata?.map((e, i) => (
                  <tr key={i}>
                    <td className="cursor-pointer relative border-Green border">
                      <Menus>
                        {report && (
                          <Link
                            to={`/sections/report/${report}/${e.date}/${e.cost_center_id}`}
                          >
                            <div className="px-3 py-1 hover:bg-slate-400 w-full">
                              {"عرض التفاصيل "}
                            </div>
                          </Link>
                        )}
                        {reportCenter && (
                          <Link
                            to={`/cost-centers/report/${reportCenter}/${e.date}`}
                          >
                            <div className="px-3 py-1 hover:bg-slate-400 w-full">
                              {"عرض التفاصيل "}
                            </div>
                          </Link>
                        )}
                        {reportCenterSection && (
                          <Link
                            to={`/sections/report/${reportCenterSection[0]}/${e.date}/${e.cost_center_id}/${reportCenterSection[1]}`}
                          >
                            <div className="px-3 py-1 hover:bg-slate-400 w-full">
                              {"عرض التفاصيل "}
                            </div>
                          </Link>
                        )}
                        {children}

                        {links &&
                          links?.map((li, i) => (
                            <Link key={i} to={li.link + e.id}>
                              <div className="px-3 py-1 hover:bg-slate-400 w-full">
                                {li.name}
                              </div>
                            </Link>
                          ))}
                        <Archive
                          archive={archive}
                          archiveFun={archiveFun}
                          id={e.id}
                          is_archived={e.is_archived}
                        />

                        {print && (
                          <div
                            onClick={() =>
                              print(`/${e.id}/report/export/pdf`, "pdf")
                            }
                            className="px-3 py-1 hover:bg-slate-400 w-full"
                          >
                            طباعة التقارير pdf
                          </div>
                        )}
                        {print && (
                          <div
                            onClick={() =>
                              print(`/${e.id}/report/export/excel`, "excel")
                            }
                            className="px-3 py-1 hover:bg-slate-400 w-full"
                          >
                            طباعة التقارير excel
                          </div>
                        )}
                        {idDel && <Delete onClick={() => idDel(e)} />}
                      </Menus>
                    </td>
                    {tbody?.map((name, i) => (
                      <>
                        <td key={i} className="border-Green border p-2">
                          {Array.isArray(e[name])
                            ? e[name].map((ele) => <div>{ele.name}</div>)
                            : e[name]
                            ? e[name]
                            : "___"}
                        </td>
                      </>
                    ))}
                  </tr>
                ))}
              <tr className="h-[150px]"></tr>
            </tbody>
          </table>
          {tdata?.length === 0 ? (
            <div className="text-center text-2xl py-3">لا يوجد بيانات</div>
          ) : (
            ""
          )}
        </Pagination>
      </div>
    </div>
  );
};

export default Table;

const Archive = ({ archiveFun, archive, id, is_archived }) => {
  const [sure, setSure] = useState(false);
  return (
    <>
      {sure && (
        <>
          <div
            className="fixed  top-0 left-0 w-full h-full bg-black bg-opacity-60 z-50"
            onClick={() => setSure(false)}
          ></div>
          <div
            className={`fixed  top-1/2 left-1/2 -translate-x-1/2 -translate-y-1/2 popup z-50 flex justify-center items-center`}
          >
            <div className="bg-white z-[60] rounded-3xl w-[500px] max-w-[500px] min-h-[200px]">
              <p className="font-semibold text-3xl text-center py-7">
                {is_archived
                  ? "هل أنت متأكد من فتح هذا العنصر ؟"
                  : "هل أنت متأكد من اقفال هذا العنصر ؟"}
              </p>
              <div className="flex items-end m-5">
                <div className="flex gap-9 mx-3">
                  <div
                    className="px-7 py-3 bg-green-600 my-2 rounded-xl text-white cursor-pointer hover:bg-opacity-75"
                    onClick={() =>
                      archiveFun(
                        `${archive}/${id}/archive?is_archived=${
                          is_archived ? 0 : 1
                        }`,
                        true
                      )
                    }
                  >
                    نعم
                  </div>
                </div>

                <button
                  onClick={() => setSure(false)}
                  className="px-7 py-3 bg-red-600 my-2 rounded-xl text-white cursor-pointer hover:bg-opacity-75"
                >
                  لا
                </button>
              </div>
            </div>
          </div>
        </>
      )}
      <div
        onClick={() => setSure(true)}
        className="px-3 py-1 hover:bg-slate-400 w-full"
      >
        {is_archived ? "فتح مركز التكلفة" : "اقفال مركز التكلفة"}
      </div>
    </>
  );
};
const Menus = ({ children }) => {
  const [menu, setMenu] = useState(false);
  const mouse = useRef();
  useEffect(() => {
    const handler = (e) => {
      if (mouse.current) {
        if (!mouse.current.contains(e.target)) {
          setMenu(false);
        }
      }
    };
    document.addEventListener("mousedown", handler);
    return () => {
      document.addEventListener("mousedown", handler);
    };
  }, []);
  return (
    <div ref={mouse} className=" transition-all ">
      <CiMenuKebab onClick={() => setMenu(!menu)} />
      {menu && (
        <div className="overflow-y-scroll scroll-none max-h-[] absolute z-50 -top-10 text-sm rounded-xl -left-28 mr-4 min-w-[130px] bg-slate-300  overflow-hidden ">
          {children}
        </div>
      )}
    </div>
  );
};

import React from "react";
import { Loading, Table, Title } from "../../components";
import { useFETCH, useFilter, usePOST } from "../../APIs/useMyAPI";
import { useParams } from "react-router-dom";

const SectionsWorkers = () => {
  const { filter } = useFilter();
  const { id } = useParams();
  const { data, isLoading } = useFETCH(
    `admin/sections/${id}/workers${
      filter.get("page") ? "?page=" + filter.get("page") : ""
    }`
  );
  const { data: section } = useFETCH(`admin/sections/${id}`);

  return (
    <div>
      <Title title={` ${section?.data.data.name} > العمال `} />
      {isLoading ? <Loading /> : ""}
      <Table
        pageCount={Math.ceil(data?.data.data.total / data?.data.data.per_page)}
        thead={[" اسم الموظف"]}
        tbody={["name"]}
        tdata={data?.data.data.data}
        // print={printClick}
      />
    </div>
  );
};

export default SectionsWorkers;

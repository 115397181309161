import React from "react";
import { Loading, Table, Title } from "../../components";
import { useFETCH, useFilter } from "../../APIs/useMyAPI";
import { useParams } from "react-router-dom";

const CostCentersWorkers = () => {
  const { filter } = useFilter();
  const { id } = useParams();
  const { data, isLoading } = useFETCH(
    `admin/cost-centers/${id}/workers${
      filter.get("page") ? "?page=" + filter.get("page") : ""
    }`
  );
  const { data: section, printClick } = useFETCH(`admin/cost-centers/${id}`);

  return (
    <div>
      <Title title={` ${section?.data.data.project_name} > العمال `} />
      {isLoading ? <Loading /> : ""}
      <Table
        pageCount={Math.ceil(data?.data.data.total / data?.data.data.per_page)}
        thead={[" اسم الموظف"]}
        tbody={["name"]}
        tdata={data?.data.data.data}
        // print={printClick}
        links={[{ name: "تقارير", link: `/cost-centers/${id}/workers/` }]}
      />
    </div>
  );
};

export default CostCentersWorkers;
// {{url}}/api/admin/cost-centers/2/workers/2/report/pdf

import React from "react";
import { Add, Loading, Table, Title } from "../../components";
import { useFETCH, useFilter } from "../../APIs/useMyAPI";

const Workers = () => {
  const { filter } = useFilter();
  const { data, deleteItem, isLoading } = useFETCH(
    `admin/workers${filter.get("page") ? "?page=" + filter.get("page") : ""}`,
    "admin/workers"
  );
  const { printClick } = useFETCH(`admin/workers`);
  return (
    <div>
      {isLoading ? <Loading /> : ""}
      <Title title="العمال" />
      <Table
        idDel={deleteItem}
        print={printClick}
        pageCount={Math.ceil(data?.data.data.total / data?.data.data.per_page)}
        thead={["اسم ", "تكلفة الساعة الانتاجية", "الرقم الوظيفي", "اسم القسم"]}
        tbody={["name", "hour_cost", "job_number", "section" ]}
        tdata={data?.data.data.data}
        links={[
          { name: "تقارير", link: "/workers/repots/" },
          { name: "تعديل", link: "/workers/" },
        ]}
      />
      <Add link="/workers/add" />
    </div>
  );
};

export default Workers;
